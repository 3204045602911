import httpClient from "./httpClient";

const END_POINT = "/api/affectation";
const addAffectation = affectation => httpClient.post(END_POINT, affectation);
const updateAffectation = affectation => httpClient.put(END_POINT, affectation);
const deleteAffectation = affectation =>
  httpClient.post(END_POINT + "/roleDelete", affectation);
const deleteAllAffectations = affectation =>
  httpClient.post(END_POINT + "/delete", affectation);
const changeAffectationTeam = affectation => httpClient.post(END_POINT + '/change-team', affectation);
const updateEstimatedHours = affectation => httpClient.post(END_POINT + "/updateEstimatedHours", affectation);
export {
  addAffectation,
  updateAffectation,
  deleteAffectation,
  deleteAllAffectations,
  changeAffectationTeam,
  updateEstimatedHours
};
