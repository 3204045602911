<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="dossiers"
      class="elevation-0"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:no-data>
        Aucun agent affecté dans ce dossier
      </template>
      <template v-slot:top>
        <div class="d-flex align-center justify-space-between">
          <span id="toolbar-title">{{ $route.query.project }}</span>
          <v-checkbox
            v-model="selectAllAgents"
            @change="toggleSelectAllAgents"
            label="Sélectionner tous les agents"
            class="mt-2"
          />
        </div>

        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="418px">
            <template v-slot:activator="{}">
              <Button
                class="mb-2"
                action_name="Ajouter"
                color="primary"
                text
                @clicked="affectToProject"
                :loading="loadingAdd"
              />
              <v-col class="col-md-2">
                <text-input
                  ref="estimatedHours"
                  class="mt-4"
                  v-model="editedItem.estimatedHours"
                  placeholder="H estimées"
                  id="estimatedHours"
                  :rules="requiredRules"
                />
              </v-col>
              <v-col class="col-md-2">
                <AutoComplete
                  id="team"
                  v-model="editedItem.team"
                  :items="teams"
                  class="mt-6"
                  placeholder="Equipe"
                />
              </v-col>
              <v-col class="col-md-2">
                <AutoComplete
                  id="role"
                  v-model="editedItem.role"
                  :items="roles"
                  class="mt-6"
                  placeholder="Role"
                  item-text="name"
                  return-object
                />
              </v-col>
              <v-col class="col-md-3">
                <AutoComplete
                  id="agent"
                  v-model="editedItem.agent"
                  :items="agents"
                  class="mt-6 agent-input"
                  placeholder="Agent"
                  item-text="name"
                  return-object
                  multiple
                  deletable-chips
                  small-chips
                />
              </v-col>
              <v-col class="col-md-2">
                <v-switch
                  class="mt-6"
                  v-model="switch1"
                  label="Afficher historique"
                  color="primary"
                  @change="handleSwitchClick"
                ></v-switch>
              </v-col>
            </template>
          </v-dialog>
          <v-dialog v-model="confirmModal" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                Etes vous sûr de supprimer cet agent de ce dossier
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-5" text @click="confirmModal = false">
                  Annuler
                </v-btn>
                <v-btn
                  color="primary darken-5"
                  text
                  @click="deleteAgentInDossier()"
                >
                  Oui
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.agent="{ item }">
        <Avatar :item="item" />
        <span class="ml-2">{{ item.name }}</span>
      </template>
      <template v-slot:item.team="{ item }">
        {{ item.affectation[0].team }}
      </template>
      <template v-slot:item.affectation[0].estimatedHours="{ item }">
        <span @click="openEditHoursDialog(item)" style="cursor: pointer;">
          {{ item.affectation[0].estimatedHours }}
        </span>
      </template>
      <template v-slot:item.affectation="{ item }">
        <template v-for="affectation in item.affectation">
          <v-chip
            class="ml-1 mt-1"
            :close="affectation.status === 1"
            :key="affectation.id"
            @click:close="handleChipDelete(affectation)"
            :color="getChipColor(affectation)"
          >
            {{ affectation.role.name }}
            <v-switch
              v-model="switchBase"
              :value="affectation.id"
              v-if="affectation.status === 1 && affectation.team === 'Base'"
              :label="affectation.team"
              color="green"
              :key="affectation.id"
              class="ml-5 mt-5 custom-switcher"
              @change="handleTeamSwitch(affectation)"
            >
              <template v-slot:label>
                <span style="color:white;font-size: 14px">{{
                  affectation.team
                }}</span>
              </template>
            </v-switch>
            <v-switch
              v-model="switchRenfort"
              :value="affectation.id"
              v-if="affectation.status === 1 && affectation.team === 'Renfort'"
              :label="affectation.team"
              color="success"
              :key="affectation.id"
              class="ml-5 mt-5 custom-switcher"
              @change="handleTeamSwitch(affectation)"
            >
              <template v-slot:label>
                <span style="color:white;font-size: 14px">{{
                  affectation.team
                }}</span>
              </template>
            </v-switch>
          </v-chip>
        </template>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu :close-on-click="true" :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" size="20" v-bind="attrs" v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <v-list>
            <v-list-item v-for="(item2, index) in roles" :key="index">
              <v-checkbox
                v-model="selected[item.id]"
                :label="item2.name"
                :value="item2.id"
                @change="handleRoleChange(item.id)"
                on-icon="mdi-check-circle-outline"
                off-icon="mdi-circle-outline"
              ></v-checkbox>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon medium @click="deleteItem(item)" size="25">
          mdi-close-circle-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="editHoursDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Modifier les heures estimées</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="editedHoursValue"
            label="Heures estimées"
            type="number"
            min="0"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark" text @click="editHoursDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="saveEditedHours"
            >Enregistrer</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { AutoComplete, Button, Avatar, TextInput } from "@/components/base";
import {
  addAffectation,
  updateAffectation,
  deleteAffectation,
  deleteAllAffectations,
  changeAffectationTeam,
  updateEstimatedHours,
} from "@/services/affectation.api";
import { getAllActiveUsers } from "@/services/users.api";
import { getDossier } from "@/services/dossiers.api";
import { getAllRoles } from "@/services/roles.api";
export default {
  components: { Button, Avatar, AutoComplete, TextInput },
  data: () => ({
    loadingAdd: false,
    switchBase: [],
    switchRenfort: [],
    switch1: false,
    confirmModal: false,
    chip1: true,
    isValid: false,
    teams: ["Base", "Renfort"],
    selected: [],
    roles: [],
    agents: [],
    items: [],
    dialog: false,
    status: 1,
    headers: [
      {
        text: "Agent",
        align: "start",
        sortable: false,
        value: "agent",
      },
      { text: "Roles", value: "affectation" },
      { text: "Heures estimées", value: "affectation[0].estimatedHours" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dossiers: [],
    editedIndex: -1,
    editedItem: {
      team: "",
      agent: [],
    },
    defaultItem: {},
    affectation: {},
    editHoursDialog: false,
    editedHoursItem: null,
    editedHoursValue: null,
    selectAllAgents: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    handleTeamSwitch(item) {
      changeAffectationTeam(item).then(() => {
        this.getDossiers(this.status);
      });
    },
    getChipColor(affectation) {
      if (affectation.status == 1) {
        return "secondary";
      } else {
        return "";
      }
    },
    handleSwitchClick() {
      if (this.switch1) {
        this.status = 0;
      } else {
        this.status = 1;
      }
      this.getDossiers(this.status);
    },
    deleteAgentInDossier() {
      deleteAllAffectations(this.affectation).then(() => {
        this.getDossiers(this.status);
        this.confirmModal = false;
        this.$swal.fire({
          title: "Utilisateur supprimer du dossier avec succès",
        });
      });
    },
    deleteItem(item) {
      this.affectation.dossier_id = item.affectation[0].dossier_id;
      this.affectation.user_id = item.affectation[0].user_id;
      this.confirmModal = true;
    },
    handleChipDelete(item) {
      if (item.status === 1) {
        deleteAffectation(item).then((response) => {
          this.$swal.fire({ title: response.data.message, timer: 500 });
          this.getDossiers(this.status);
        });
      }
    },
    getDossiers(status) {
      getDossier(this.$route.query.project, status).then((response) => {
        this.dossiers = response.data;
        for (let i in response.data) {
          this.selected[response.data[i].id] = [];
          for (let j in response.data[i].affectation) {
            if (response.data[i].affectation[j].status == 1) {
              this.switchBase.push(response.data[i].affectation[j].id);
              this.selected[response.data[i].id].push(
                response.data[i].affectation[j].role_id
              );
            } else {
              this.switchRenfort.push(response.data[i].affectation[j].id);
            }
          }
        }
      });
    },
    handleRoleChange(id) {
      this.editedItem.user_id = id;
      this.editedItem.name = this.$route.query.project;
      this.editedItem.roles = this.selected[this.editedItem.user_id];
      updateAffectation(this.editedItem).then(() => {
        getDossier(this.$route.query.project, this.status).then((response) => {
          this.dossiers = response.data;
          this.$swal.fire({ title: "Rôle modifié avec succès", timer: 500 });
        });
      });
    },
    affectToProject() {
      if (!this.editedItem.estimatedHours) {
        this.$swal.fire({
          title: "Le champ Heures estimées est obligatoire",
          icon: "error",
        });
        return;
      }
      this.loadingAdd = true;
      this.editedItem.name = this.$route.query.project;
      addAffectation(this.editedItem).then(() => {
        this.loadingAdd = false;
        this.selectAllAgents = false;
        this.getDossiers(this.status);
        this.$swal.fire({ title: "Utilisateur affecté avec succès" });
        this.editedItem = {};
        this.$nextTick(() => {
          this.$refs.estimatedHours.resetValidation();
        });
      });
    },
    openEditHoursDialog(item) {
      this.editedHoursItem = item;
      this.editedHoursValue = item.affectation[0].estimatedHours;
      this.editHoursDialog = true;
    },
    saveEditedHours() {
      if (this.editedHoursItem && this.editedHoursValue !== null) {
        this.editedHoursItem.affectation[0].estimatedHours = this.editedHoursValue;
        const updatedAffectation = {
          ...this.editedHoursItem,
          name: this.$route.query.project,
          user_id: this.editedHoursItem.id,
        };
        updateEstimatedHours(updatedAffectation).then(() => {
          this.$swal.fire({
            title: "Heures estimées modifiées avec succès",
            timer: 500,
          });
          this.editHoursDialog = false;
        });
      }
    },
    initialize() {
      getAllRoles().then((response) => {
        this.roles = response.data;
      });
      getAllActiveUsers().then((response) => {
        this.agents = response.data;
      });
      this.getDossiers(this.status);
    },
    toggleSelectAllAgents() {
      if (this.selectAllAgents) {
        this.editedItem.agent = [...this.agents];
      } else {
        this.editedItem.agent = [];
      }
    },
  },
};
</script>
<style scoped>
.v-icon.outlined {
  border: 1px solid currentColor;
  border-radius: 50%;
  padding: 3px;
}

.agent-input ::v-deep .v-input__slot {
  max-height: 150px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::v-deep .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit;
}
</style>
